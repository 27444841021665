import React, { Component } from "react";
import app from "../../utils/fire";
import styles from "./Auth.module.scss";
import nftFeverLogo from "../../assets/NFTFeverSmall.png";
import closeIcon from "../../assets/cross.png";
import "firebase/compat/firestore";

import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { updateProfile, signOut, sendPasswordResetEmail } from "firebase/auth";

var db = app.firestore();
var auth = getAuth(app);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refUsername: this.props.referrer,
      name: "",
      email: "",
      password: "",
      message: "",
      passwordMatch: false,
      mode: this.props.mode,
      success: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);

    this.signUp = this.signUp.bind(this);
    this.logOut = this.logOut.bind(this);
    this.logIn = this.logIn.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.referrer = this.referrer.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
  }

  componentDidMount() {
    // checks if the user is logged in
    console.log("verify?");

    // get the usernames

    if (this.props.user && !this.props.mainPage) {
      this.logOut();
    }
    onAuthStateChanged(auth, (user) => {
      if (user) {
        var uid = user.uid;
        var emailVerified = user.emailVerified;

        if (emailVerified === true) {
          // check if the account exists on firebase
          // if not, add it
          db.collection("verifiedEmails")
            .get()
            .then((querySnapshot) => {
              var found = false;
              querySnapshot.forEach((res) => {
                if ((res.id).toString() == uid.toString()) {
                  found = true;
                }
              });

              if (found == false) {
                db.collection("verifiedEmails")
                .doc(uid)
                .set({
                  name: user.displayName,
                  code: uid.slice(0, 5),
                  email: user.email,
                  count: 0,
                });  
              }
            });

          this.props.setUser(user);
        } else {
          this.setState({
            message: "Verify your email first",
          });
        }
      } else {
        console.log("not signed in");
      }
    });
  }

  logIn(event) {
    event.preventDefault();
    console.log("login");

    var { email, password } = this.state;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // verify email first
        if (userCredential.user.emailVerified === true) {
          this.props.setAccInfo(false);
          this.props.setUser(userCredential.user);
        } else {
          this.setState({
            message: "Verify your email first",
          });
        }
      })
      .catch((error) => {
        this.setState({
          message: error.message,
        });
        console.log(error);
      });
  }

  referrer() {
    const { refUsername } = this.state;

    // console.log("reffere", refUsername);

    if (refUsername.toString().length === 0) {
      console.log("no referrer");
    } else {
      // console.log("referrer id", refUsername);

      // db.collection("verifiedEmails")
      //   .doc(refUsername)
      //   .get()
      //   .then((querySnapshot) => {
      //     var data = querySnapshot.data();
      //     db.collection("verifiedEmails")
      //       .doc(refUsername)
      //       .update({
      //         count: parseInt(data["count"]) + 1,
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //       });
      //   });

      db.collection("verifiedEmails")
        .where("code", "==", refUsername)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (res) => {
            var data = res.data();

            db.collection("verifiedEmails")
              .doc(res.id)
              .update({
                count: parseInt(data["count"]) + 1,
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
    }
  }

  signUp(event) {
    event.preventDefault();

    var actionCodeSettings;

    console.log(actionCodeSettings);
    // this.referrer();

    if (this.state.passwordMatch === true) {
      var { name, email, password } = this.state;

      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

          actionCodeSettings = {
            url: "http://localhost:3000/" + (userCredential.user.uid.slice(0, 5)).toString(),
            handleCodeInApp: true
          };
      
          // update profile name
          updateProfile(auth.currentUser, {
            displayName: name,
          })
            .then(() => {
              // update the firebase with data
              db.collection("unverifiedEmails")
              .doc(userCredential.user.uid.slice(0, 5))
              .set({
                name: name,
                code: userCredential.user.uid,
                email: email,
                count: 0,
                ref: (this.props.referrer).toString(),
              });
            })
            .catch((error) => {
              console.log(error);
            });

          // send email for verification
          sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
            this.setState({
              message: "Email verification sent",
              success: true,
            });
          });

          // once the email has been verified, we automatically sign the user in?

          // this.checkMail = setInterval(() => {
          //   if (userCredential.user.emailVerified) {
          //     console.log("user verified");
          //     clearInterval(this.checkMail);

          //     signInWithEmailAndPassword(auth, email, password)
          //       .then((userCredential) => {
          //         // add the user to the email list

          //         // console.log("before reffere ", this.state.refUsername);

          //         db.collection("verifiedEmails")
          //           .doc(userCredential.user.uid)
          //           .set({
          //             name: name,
          //             code: userCredential.user.uid.slice(0, 5),
          //             email: email,
          //             count: 0,
          //           });
          //       })
          //       .then((_) => {
          //         this.referrer();
          //       })
          //       .then((_) => {
          //         this.props.setUser(userCredential.user);
          //         //location.reload();
          //       })
          //       .catch((error) => {
          //         this.setState({
          //           message: error.message,
          //         });
          //         console.log(error);
          //       });
          //   }
          //   userCredential.user.reload();
          // }, 2000);
        })
        .catch((error) => {
          this.setState({
            message: error.message,
          });
          console.log(error);
        });


        
    } else {
      this.setState({
        message: "Passwords do not match",
      });
    }
  }

  forgotPassword(event) {
    event.preventDefault();

    var { email } = this.state;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        this.setState({
          message: "Reset Link has been sent to your email",
        });
      })
      .catch((error) => {
        this.setState({
          message: error.message,
        });
        console.log(error);
      });
  }

  logOut(event) {
    signOut(auth)
      .then(() => {
        this.props.setUser(null);
      })
      .catch((error) => {
        this.setState({
          message: error,
        });
      });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleUsernameChange(event) {
    this.setState({ refUsername: event.target.value });
  }

  handleConfirmPasswordChange(event) {
    if (this.state.password !== event.target.value) {
      this.setState({
        message: "Passwords do not match",
        passwordMatch: false,
      });
    } else {
      this.setState({
        message: "Passwords match",
        passwordMatch: true,
      });
    }
  }

  render() {
    if (this.props.user !== null) {
      return (
        <div
          className={`${styles.container} ${
            this.props.mainPage && styles.mainPage
          }`}
        >
          <div
            className={styles.close}
            onClick={() => this.props.setAccInfo(false)}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className={styles.sideBorder}></div>
          <p style={{ lineHeight: "25px", marginBottom: "20px" }}>
            Logged in as: <br />
            Name: {this.props.user.displayName} <br />
            Email: {this.props.user.email}
          </p>
          <div className={styles.wrapper}>
            <button onClick={this.logOut}>SIGN OUT</button>
          </div>
        </div>
      );
    }
    if (this.state.success) {
      return (
        <div
          className={`${styles.container} ${
            this.props.mainPage && styles.mainPage
          }`}
        >
          <div
            className={styles.close}
            onClick={() => this.props.setAccInfo(false)}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className={styles.sideBorder}></div>
          <p style={{ textAlign: "center" }}>
            A verification email has been sent please click the link inside it
            to activate your account.
          </p>
        </div>
      );
    }
    if (this.state.mode === 0) {
      return (
        <div
          className={`${styles.container} ${
            this.props.mainPage && styles.mainPage
          }`}
        >
          <div
            className={styles.close}
            onClick={() => this.props.setAccInfo(false)}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className={styles.sideBorder}></div>
          <img src={nftFeverLogo} alt="" />
          <form onSubmit={this.logIn}>
            <label>
              <input
                type="email"
                onChange={this.handleEmailChange}
                placeholder="Email"
              />
              <input
                type="password"
                onChange={this.handlePasswordChange}
                placeholder="Password"
              />
            </label>
            <div className={styles.wrapper}>
              <input type="submit" value="SIGN IN" />
            </div>
          </form>
          <p
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={() => this.setState({ mode: 1 })}
            className={styles.switch}
          >
            Don't have an account? Create one here
          </p>
          <p
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={this.forgotPassword}
            className={styles.switch}
          >
            Forgot Password
          </p>

          <h4 style={{ color: "red", marginTop: "10px" }}>
            {" "}
            {this.state.message}
          </h4>
        </div>
      );
    } else {
      return (
        <div
          className={`${styles.container} ${
            this.props.mainPage && styles.mainPage
          }`}
        >
          <div
            className={styles.close}
            onClick={() => this.props.setAccInfo(false)}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className={styles.sideBorder}></div>
          <img src={nftFeverLogo} alt="" />
          <form onSubmit={this.signUp}>
            <label>
              <input
                type="text"
                onChange={this.handleNameChange}
                placeholder="Display Name"
              />
              <input
                type="email"
                onChange={this.handleEmailChange}
                placeholder="Email"
              />
              <input
                type="password"
                onChange={this.handlePasswordChange}
                placeholder="Password"
              />
              <input
                type="password"
                onChange={this.handleConfirmPasswordChange}
                placeholder="Confirm Password"
              />
              <p
                style={{
                  opacity: "0.5",
                  marginBottom: "20px",
                  fontWeight: "300",
                }}
              >
                Referred by: {this.props.referrer || "None"}
              </p>
            </label>
            <div className={styles.wrapper}>
              <input type="submit" value="SIGN UP" />
            </div>
          </form>
          <p
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => this.setState({ mode: 0 })}
            className={styles.switch}
          >
            Already have an account? Login here
          </p>
          <h4> {this.state.message}</h4>
        </div>
      );
    }
  }
}

export default Login;
