import React, { Component } from "react";
import Spinner from "react-spinkit";
// import { authRef } from '../reference';
import app from "../../utils/fire";
import { getAuth, applyActionCode } from "firebase/auth";
import "firebase/compat/firestore";
import styles from "./Handlers.module.scss";
import { Link } from "react-router-dom";
import nftFeverLogo from "../../assets/NFTFeverSmall.png";

var auth = getAuth(app);
var db = app.firestore();

// move the referral system here
// how are we gonna get the refferal code here?

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionCode: this.props.actionCode,
      apiKey: this.props.apiKey,
      userId: this.props.userId,
      message: "Please wait while we verify!",
      verifiedCode: false,
      continueUrl: this.props.continueUrl,
      uData: {
        name: null,
        email: null,
        ref: null,
        code: null,
      },
    };

    this.verified = this.verified.bind(this);
  }

  async verified(event) {
    db.collection("unverifiedEmails")
      .doc(this.state.userId)
      .get()
      .then(async (querySnapshot) => {
        var data = querySnapshot.data();

        await db
          .collection("verifiedEmails")
          .doc(data["code"].toString())
          .set({
            name: data["name"],
            email: data["email"],
            code: data["code"].slice(0, 5),
            count: 0,
          });

        // update the count value
        await db
          .collection("verifiedEmails")
          .where("code", "==", data["ref"].toString())
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (res) => {
              var data = res.data();

              db.collection("verifiedEmails")
                .doc(res.id)
                .update({
                  count: parseInt(data["count"]) + 1,
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          });
      });
  }

  componentDidMount() {
    // console.log(this.state.userId);

    if (this.state.verifiedCode) {
      this.setState({
        message: "Email has been verified.",
      });
    }

    applyActionCode(auth, this.state.actionCode)
      .then(async (resp) => {
        await this.verified();
        this.setState({
          message: "Email Verified",
          verifiedCode: true,
        });
      })
      .catch((error) => {
        this.setState({
          message:
            "Code is invalid or expired. Verify your email address again.",
        });
        console.log(error);
      });
  }

  render() {
    let component;

    if (!this.state.verifiedCode) {
      component = (
        <div className={styles.container}>
          <div className={styles.modal}>
            <div className={styles.sideBorder}></div>
            <img src={nftFeverLogo} alt="" />
            <Spinner
              style={{ marginBottom: "15px" }}
              spinnerName="three-bounce"
            />
            <p> {this.state.message} </p>
          </div>
        </div>
      );
    } else {
      component = (
        <div className={styles.container}>
          <div className={styles.modal}>
            <div className={styles.sideBorder}></div>
            <img src={nftFeverLogo} alt="" />
            <p>Thank You, your email has been confirmed.</p>
            <p>You can now sign in to your account.</p>
            <div className={styles.wrapper}>
              <Link to={"/"}>
                <button>continue</button>
              </Link>
            </div>
            {/* <h1> {this.state.message} </h1> */}
          </div>
        </div>
      );
    }

    return component;
  }
}

export default VerifyEmail;
