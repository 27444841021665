import React from 'react';
// import RecoverEmail from './RecoverEmail';
import ResetPassword from './resetPassword';
import VerifyEmail from './verifyEmail';
import { useLocation } from 'react-router-dom';


// http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy
// https://nftfever.io/__/auth/action
// http://localhost:3000/action
// https://nftfever.io/action


// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience
const getQueryParams = (s?: string): Map<string, string> => {
  if (!s || typeof s !== 'string' || s.length < 2) {
    return new Map();
  }

  const a: [string, string][] = s
    .substr(1) // remove `?`
    .split('&') // split by `&`
    .map(x => {
      const a = x.split('=');
      return [a[0], a[1]];
    }); // split by `=`

  return new Map(a);
};

function Action(props) {
  const s = useLocation().search;
  const locationData = getQueryParams(s);

  // return(
  //   <div>
  //     Hello world
  //   </div>
  // );

  // Get the action to complete.
  const mode = locationData.get("mode");
  // Get the one-time code from the query parameter.
  const actionCode = locationData.get("oobCode");
  // (Optional) Get the API key from the query parameter.
  const apiKey = locationData.get("apiKey");
  var continueUrl = locationData.get("continueUrl");
  var userId = null;

  try {
    userId = continueUrl.split("/")[3].split("=")[0];
    continueUrl = continueUrl.split("/")[2];
  } catch(error) {
    userId = null;
    continueUrl = null;
  }

  // console.log(refUsername, continueUrl);

  // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Display email recovery handler and UI.
      return <ResetPassword 
        actionCode={actionCode} 
        apiKey={apiKey}
        userId={userId}
        continueUrl={continueUrl} 
      />;
    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail 
        actionCode={actionCode} 
        apiKey={apiKey}
        userId={userId}
        continueUrl={continueUrl}
      />;
    default:
      // Error: invalid mode.
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
}

export default Action;