import styles from "./Nav.module.scss";
import lockIcon from "../../assets/Locked.png";
import playIcon from "../../assets/PlayIcon.png";

export default function Lesson({
  id,
  title,
  link,
  thumbnail,
  resources,
  setCurrentLesson,
  user,
  setAccInfo,
  currentLesson,
}) {
  return (
    <div
      className={`${styles.lessonWrapper} ${
        currentLesson.id === id && styles.selected
      } ${!user && styles.hided}`}
    >
      <div
        onClick={() => {
          if (user) {
            setCurrentLesson({
              id: id,
              title: title,
              resources: resources,
              link: link,
            });
            setAccInfo(false);
          } else {
            setAccInfo(true);
          }
        }}
        className={`${styles.lesson} ${!user && styles.hided}`}
      >
        <div className={styles.thumbnail}>
          <img src={thumbnail} alt="" />
        </div>
        <div className={styles.text}>
          <p>{title}</p>
        </div>
        {!user && id === 1 && (
          <div className={styles.hider}>
            <img src={lockIcon} alt="" /> SIGN IN
          </div>
        )}
        {user && <img className={styles.playIcon} src={playIcon} alt="" />}
      </div>
    </div>
  );
}
