import { useEffect } from "react";
import styles from "./Nav.module.scss";
import nftFeverLogo from "../../assets/NFTFeverSmall.png";
import Lesson from "./lesson";
import closeIcon from "../../assets/arrow.png";
import { lessons } from "./vidsData";

export default function Nav({
  setCurrentLesson,
  navOpen,
  setNavOpen,
  user,
  setAccInfo,
  currentLesson,
  setNext,
  next,
}) {
  useEffect(() => {
    setNext(lessons[0]);
  }, []);
  useEffect(() => {
    if (currentLesson.id < lessons.length) {
      setNext(lessons[currentLesson.id]);
    } else {
      setNext(lessons[0]);
    }
  }, [currentLesson]);
  useEffect(() => {
    if (user) {
      setCurrentLesson(lessons[0]);
    }
  }, [user]);
  return (
    <div className={`${styles.container} ${navOpen && styles.opened}`}>
      <div className={styles.logo}>
        <img src={nftFeverLogo} alt="" />
        <p>The FREE NFT crash course for beginners</p>
        <button onClick={() => setNavOpen(false)}>
          <img src={closeIcon} alt="" />
        </button>
      </div>
      <div className={styles.head}>
        <p>Lessons:</p>
      </div>
      <div className={styles.lessonsContainer}>
        {lessons.map((el, ind) => (
          <Lesson
            id={el.id}
            title={el.title}
            link={el.link}
            desc={el.description}
            thumbnail={el.thumb}
            resources={el.resources}
            key={ind}
            setCurrentLesson={setCurrentLesson}
            user={user}
            setAccInfo={setAccInfo}
            currentLesson={currentLesson}
          />
        ))}
      </div>
    </div>
  );
}
