import styles from "./Leaderboard.module.scss";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  PinterestIcon,
} from "react-share";
import copyIcon from "../../assets/NFTF_CopyLink.png";
import checkedIcon from "../../assets/check.png";
import card from "../../assets/Prize/Card.png";
import coin from "../../assets/Prize/Coin.png";
import { useEffect, useState } from "react";

export default function Leaderbord({
  shareUrl,
  size,
  title,
  mediaUrl,
  leaderboard,
  user,
  setAccInfo,
}) {
  const [userData, setUserData] = useState({ place: "00" });
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (user) {
      for (let i in leaderboard) {
        if (leaderboard[i].email === user.email) {
          let obj = {};
          obj = leaderboard[i];
          obj.place = parseInt(i) + 1;
          setUserData(obj);
        }
      }
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);
  return (
    <div className={styles.container}>
      <div>
        <h3>help spread the word and win a range of cool prizes.</h3>
        <p>all you need to do is share nft fever using your custom link:</p>
        <div className={styles.wrapper}>
          <div
            onClick={() => {
              if (!user) setAccInfo(true);
            }}
            className={styles.shareIcons}
          >
            {user ? (
              <>
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={size} round />
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={size} round />
                </TwitterShareButton>

                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={size} round />
                </LinkedinShareButton>

                <WhatsappShareButton url={shareUrl} title={title}>
                  <WhatsappIcon size={size} round />
                </WhatsappShareButton>

                <PinterestShareButton url={shareUrl} media={mediaUrl}>
                  <PinterestIcon size={size} round />
                </PinterestShareButton>
                <img
                  style={{ width: "41px", height: "41px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(shareUrl);
                    setCopied(true);
                  }}
                  src={copied ? checkedIcon : copyIcon}
                  alt=""
                />
              </>
            ) : (
              <p style={{ margin: "5px", cursor: "pointer" }}>
                SIGN IN TO GET YOUR LINK
              </p>
            )}
          </div>
        </div>
        <div className={styles.prizes}>
          <div>
            <img src={coin} alt="" />
            <p>2nd PLACE</p>
            <p>$200 IN ETH</p>
          </div>
          <div>
            <img src={card} alt="" />
            <p>1st PLACE</p>
            <p>A MILLION DOLLAR VAULT KEY CARD NFT</p>
          </div>
          <div>
            <img src={coin} alt="" />
            <p>3rd PLACE</p>
            <p>$50 IN ETH</p>
          </div>
        </div>
        <p>
          <span>4th-50th place</span> <br />
          whitelist spot for our next to be announced nft project!
        </p>
        <p>competition ends february 1st 6pm utc</p>
      </div>
      <div className={styles.board}>
        <div className={styles.header}>
          <div>
            <p>
              {leaderboard[1].name} <br />
              <span>{leaderboard[1].count}</span>
            </p>
          </div>
          <div>
            <p>
              {leaderboard[0].name} <br />
              <span>{leaderboard[0].count}</span>
            </p>
          </div>
          <div>
            <p>
              {leaderboard[2].name} <br />
              <span>{leaderboard[2].count}</span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <p>#{userData.place}</p>
            <p>your score</p>
          </div>
          <div>{userData.count || "000"}</div>
        </div>
        <div className={styles.list}>
          {leaderboard.map((el, ind) => {
            if (ind > 2) {
              return (
                <div>
                  <div>
                    <p>#{ind + 1}</p>
                    <p>{el.name}</p>
                  </div>
                  <div>{el.count}</div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
