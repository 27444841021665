import styles from "./Footer.module.scss";
import nftFeverIcon from "../../assets/greynftlogo.png";
import nexoverseIcon from "../../assets/NexoVerseSmall.png";
import mdvIcon from "../../assets/MillionDollarVaultSmall.png";
import nexogamesIcon from "../../assets/NexoGamesSmall.png";

export default function Footer() {
  return (
    <div className={styles.container}>
      <div>
        <img src={nftFeverIcon} alt="" />
        <p>
          © 2022 NFTFever.io Is part of the NexoVerse Network. All rights
          reserved.
        </p>
      </div>
      <div>
        <img src={nexoverseIcon} alt="" />
        <img
          onClick={() =>
            window.open("https://milliondollarvault.io/", "_blank")
          }
          src={mdvIcon}
          alt=""
        />
        <img
          onClick={() => window.open("https://nexogames.com/", "_blank")}
          src={nexogamesIcon}
          alt=""
        />
      </div>
    </div>
  );
}
