import React, { Component } from "react";
import Spinner from "react-spinkit";
import styles from "./Handlers.module.scss";
import { Link } from "react-router-dom";
import nftFeverLogo from "../../assets/NFTFeverSmall.png";
import app from "../../utils/fire";

import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
var auth = getAuth(app);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionCode: this.props.actionCode,
      apiKey: this.props.apiKey,
      userId: this.props.userId,
      message: "Please Wait",
      resetCompleted: false,
      verifiedCode: false,
      continueUrl: this.props.continueUrl,
      password: "",
      expired: true,
      email: ""
    };

    this.resetPassword = this.resetPassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  
  handleConfirmPasswordChange(event) {
    if (this.state.password !== event.target.value) {
      this.setState({
        verifiedCode: false,
        message: "Passwords do not match"
      });
    } else {
      this.setState({
        verifiedCode: true,
        message: "Passwords match"
      });
    }
  }

  componentDidMount() {
    verifyPasswordResetCode(auth, this.state.actionCode).then((email) => {
      this.setState({
        email: email,
        expired: false,
        message: ""
      });
    }).catch((error) => {
      console.log(error);
      this.setState({
        expired: true,
        message: "The link has expired"
      });
    })
  }


  resetPassword(event) {
    event.preventDefault();
    console.log("reset password");

    confirmPasswordReset(auth, this.state.actionCode, this.state.password).then((resp) => {
      this.setState({
        resetCompleted: true
      });
    }).catch((error) => {
      this.setState({
        message: error
      });
      console.log(error);
    });
  }

  render() {
    let component;

    if (!this.state.resetCompleted && !this.state.expired) {
      component = (
        <div className={styles.container}>
          <div>
            <div className={styles.sideBorder}></div>
            <img src={nftFeverLogo} alt="" />
            
            <form onSubmit={this.resetPassword}>
            <label>
              <p
                style={{
                  opacity: "0.5",
                  marginBottom: "20px",
                  fontWeight: "300",
                }}
              > {this.state.email} </p>
              <input
                type="password"
                onChange={this.handlePasswordChange}
                placeholder="Password"
              />
              <input
                type="password"
                onChange={this.handleConfirmPasswordChange}
                placeholder="Confirm Password"
              />
            </label>

            <div className={styles.wrapper}>
              <input type="submit" value="CHANGE PASSWORD" />
            </div>                   
          </form>
          
          <p> {this.state.message} </p>
          </div>
        </div>
      );
    } 
    
    else if (this.state.expired) {
      component = (
        <div className={styles.container}>
          <div className={styles.modal}>
            <div className={styles.sideBorder}></div>
            <img src={nftFeverLogo} alt="" />
            <p> {this.state.message} </p>
            </div>
        </div>
      );
    }
    else {
      component = (
        <div className={styles.container}>
          <div className={styles.modal}>
            <div className={styles.sideBorder}></div>
            <img src={nftFeverLogo} alt="" />
            <p>Thank you, your password has been reset.</p>
            <p>You can now sign in to your account.</p>
            <div className={styles.wrapper}>
              <Link to={"/"}>
                <button>continue</button>
              </Link>
            </div>
            {/* <h1> {this.state.message} </h1> */}
          </div>
        </div>
      );
    }

    return component;
  }
}

export default ResetPassword;
