import styles from "./Dis.module.scss";
import closeIcon from "../../assets/cross.png";
import nftFeverLogo from "../../assets/NFTFeverSmall.png";
import mdvLogo from "../../assets/MillionDollarVaultSmall.png";

export default function Dis({ setDiscordModal }) {
  return (
    <div className={styles.bg}>
      <div
        className={styles.clickAway}
        onClick={() => setDiscordModal(false)}
      ></div>
      <div className={styles.container}>
        <div className={styles.sideBorder}></div>
        <div className={styles.close} onClick={() => setDiscordModal(false)}>
          <img src={closeIcon} alt="" />
        </div>

        <div className={styles.header}>
          <img src={nftFeverLogo} alt="" />+<img src={mdvLogo} alt="" />
        </div>
        <div className={styles.content}>
          <p>
            As we already have an amazing Discord community for the Million
            Dollar Vault NFT , we decided to also use this for NFTFever.{" "}
          </p>
          <p>
            Just click the button below to join and start talking with the team
            and other community members. See you inside.
          </p>
        </div>
        <div className={styles.wrapper}>
          <button
            onClick={() =>
              window.open(
                "https://discord.com/invite/milliondollarvault",
                "_blank"
              )
            }
          >
            JOIN US ON DISCORD
          </button>
        </div>
      </div>
    </div>
  );
}
