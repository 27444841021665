import firebase from "firebase/compat/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD3u4Tjg7B6sl9yDaR_DTI0sJIjSiDoa5k",
  authDomain: "nftfever-c876f.firebaseapp.com",
  projectId: "nftfever-c876f",
  storageBucket: "nftfever-c876f.appspot.com",
  messagingSenderId: "1000867002748",
  appId: "1:1000867002748:web:14080ddfd1ce1341260cf9",
  measurementId: "G-P0BMW6MB49",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
