import thumbnail11 from "../../assets/NewVidThumbs/NFTFever_L_0000_Slide14.png";
import thumbnail10 from "../../assets/NewVidThumbs/NFTFever_L_0001_Slide13.png";
import thumbnail9 from "../../assets/NewVidThumbs/NFTFever_L_0002_Slide12.png";
import thumbnail5 from "../../assets/NewVidThumbs/NFTFever_L_0003_Slide11.png";
import thumbnail8 from "../../assets/NewVidThumbs/NFTFever_L_0004_Slide10.png";
import thumbnail6 from "../../assets/NewVidThumbs/NFTFever_L_0005_Slide9.png";
import thumbnail7 from "../../assets/NewVidThumbs/NFTFever_L_0006_Slide8.png";
import thumbnail2 from "../../assets/NewVidThumbs/NFTFever_L_0007_Slide7.png";
import thumbnail1 from "../../assets/NewVidThumbs/NFTFever_L_0008_Slide6.png";
import thumbnail4 from "../../assets/NewVidThumbs/NFTFever_L_0009_Slide5.png";
import thumbnail0 from "../../assets/NewVidThumbs/NFTFever_L_0010_Slide4.png";
import thumbnail12 from "../../assets/NewVidThumbs/NFTFever_L_0011_Slide3.png";
import thumbnail3 from "../../assets/NewVidThumbs/NFTFever_L_0012_Slide2.png";

import app from "../../utils/fire.js";
// import "firebase/compat/firestore";
// var db = app.firestore();

var lessons = [
  {
    title: "1: What is An NFT",
    link: "665696226?h=75ee2799b5",
    id: 1,
    resources: null,
  },
  {
    link: "665714876?h=fbf7b07ef1",
    resources: [
      {
        link: "https://metamask.io/",
        title: "MetaMask",
      },
    ],
    title: "2: Setting Up A Wallet",
    id: 2,
  },
  {
    title: "3: Adding Crypto To A Wallet",
    id: 3,
    resources: [
      {
        title: "Crypto.com",
        link: "https://crypto.com/",
      },
      {
        title: "CoinBase ",
        link: "https://www.coinbase.com/",
      },
    ],
    link: "665713801?h=813a1ba7d8",
  },
  {
    link: "665697361?h=175fb08931",
    resources: null,
    id: 4,
    title: "4: Supported Blockchains",
  },
  {
    id: 5,
    title: "5: ETH Gas Fees",
    resources: null,
    link: "665699446?h=c169baa94f",
  },
  {
    link: "665703889?h=2e423636bd",
    id: 6,
    title: "6: Discord",
    resources: [
      {
        link: "https://discord.com/",
        title: "Discord ",
      },
    ],
  },
  {
    title: "7: OpenSea",
    id: 7,
    Resources: [
      {
        link: "https://opensea.io/",
        title: "OpenSea ",
      },
    ],
    link: "665708252?h=e1cb336676",
  },
  {
    link: "665707021?h=59b9d0b61a",
    resources: [
      {
        title: "Tweet Deck",
        link: "https://tweetdeck.twitter.com/",
      },
      {
        title: "Rarity Sniper",
        link: "https://raritysniper.com/",
      },
      {
        link: "https://rarity.tools/upcoming/",
        title: "Rarity Tools",
      },
      {
        link: "https://theniftyshow.com/",
        title: "Nifty Show",
      },
      {
        link: "https://upcomingnft.art/",
        title: "UpcomingNFT",
      },
      {
        title: "NFT Calendar",
        link: "https://nftcalendar.io/",
      },
      {
        title: "NFT Evening",
        link: "https://nftevening.com/",
      },
      {
        title: "CoinMarketCap ",
        link: "https://coinmarketcap.com/",
      },
      {
        link: "https://icy.tools/",
        title: "Icy.Tools",
      },
    ],
    id: 8,
    title: "8: Finding NFTS",
  },

  {
    link: "665704925?h=80571b5663",
    id: 9,
    title: "9: Do Your Own Research",
    resources: null,
  },
  {
    link: "665709116?h=0e4976fdc7",
    resources: [
      {
        title: "Whitelist Tracking Sheet",
        link: "https://docs.google.com/spreadsheets/d/1jdlMisFwA2YbC4zRneOnbcxAbwou26SS7oiRkXJAf8o/edit?usp=sharing",
      },
    ],
    id: 10,
    title: "10: Whitelist",
  },
  {
    title: "11: Buying & Tracking NFTS",
    link: "665702158?h=0dd6944003",
    id: 11,
    resources: [
      {
        link: "https://docs.google.com/spreadsheets/d/1jdlMisFwA2YbC4zRneOnbcxAbwou26SS7oiRkXJAf8o/edit?usp=sharing",
        title: "NFT Tracking Sheet",
      },
    ],
  },
  {
    title: "12: Avoiding Scams",
    id: 12,
    resources: null,
    link: "665701136?h=4589aff318",
  },
  {
    title: "13: Crypto Slang",
    resources: null,
    id: 13,
    link: "665703086?h=5c34c68af4",
  },
];

const lessonsData = [
  {
    title: "1:wallet",
    thumb: thumbnail0,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail1,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail2,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail3,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail4,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail5,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail6,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail7,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail8,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail9,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail10,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail11,
    description: "Installing and creating a wallet using MetaMask",
  },
  {
    title: "2:wallet",
    thumb: thumbnail12,
    description: "Installing and creating a wallet using MetaMask",
  },
];

// lazy workaround for now

for (var i in lessons) {
  lessons[i]["thumb"] = lessonsData[i]["thumb"];
}

// db.collection("videos").get().then((querySnapshot) => {
//   var lessonData = [];

//   querySnapshot.forEach(element => {
//     lessonData.push(element.data());
//   });

//   console.log(lessonData);

//   for(var i in lessonData) {
//     lessons[i]["title"] = lessonData[i]["title"];
//     lessons[i]["link"] = lessonData[i]["link"];
//   }

// });

// lessons = lessons.sort();

// console.log(lessons);

export { lessons };
