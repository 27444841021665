import { useEffect, useState } from "react";
import styles from "./Main.module.scss";
import fbIcon from "../../assets/NFTF_Facebook.png";
import copyIcon from "../../assets/NFTF_CopyLink.png";
import liIcon from "../../assets/NFTF_Linkedin.png";
import twitterIcon from "../../assets/NFTF_Twitter.png";
import waIcon from "../../assets/NFTF_Whatsapp.png";
import banner from "../../assets/Banner23.png";
import Login from "../auth";
import Leaderbord from "../leaderboardPage";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Action from "../handlers";

// Twitter
// Telegram
// Whatsapp
// LinkedIn
// Pinterest

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  PinterestIcon,
} from "react-share";
import checkedIcon from "../../assets/check.png";

var shareUrl = "https://nftfever.io/?ref=";

export default function Main({
  currentLesson,
  user,
  referrer,
  setReferrer,
  setUser,
  setAccInfo,
  accInfo,
  next,
  setCurrentLesson,
  leadPage,
  setLeadPage,
  leaderboard,
}) {
  var title = "Share NFTFever";
  var size = "40";
  var mediaUrl =
    "https://nftfever.io/static/media/NFTFeverSmall.572c7c030c6ecda11894.png";
  if (referrer !== null) {
    var shareUrl = "https://nftfever.io/?ref=" + referrer.slice(0, 5);
  }
  // console.log(shareUrl);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);
  return (
    <div className={`${styles.container} ${!accInfo && styles.centered}`}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              !accInfo && !leadPage ? (
                <>
                  <h1>{currentLesson.title}</h1>
                  <div className={styles.vidsContainer}>
                    <div className={styles.mainVidContainer}>
                      <iframe
                        src={`https://player.vimeo.com/video/${currentLesson.link}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        title="Million Dollar Vault"
                      ></iframe>
                      <div className={styles.resources}>
                        {currentLesson.resources && <p>Resources:</p>}
                        {currentLesson.resources &&
                          currentLesson.resources.map((el, ind) => (
                            <button
                              key={ind}
                              onClick={() => window.open(el.link, "_blank")}
                            >
                              {el.title}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className={styles.others}>
                      {currentLesson.id === 0 && !user ? (
                        <div className={styles.formWrapper}>
                          <Login
                            user={user}
                            mode={1}
                            referrer={referrer}
                            setReferrer={setReferrer}
                            setUser={setUser}
                            setAccInfo={setAccInfo}
                            accInfo={accInfo}
                            mainPage={true}
                          />
                        </div>
                      ) : (
                        <div className={styles.others}>
                          <div>
                            <p>Share:</p>
                            <div className={styles.shareIcons}>
                              <FacebookShareButton url={shareUrl} quote={title}>
                                <FacebookIcon size={size} round />
                              </FacebookShareButton>

                              <TwitterShareButton url={shareUrl} title={title}>
                                <TwitterIcon size={size} round />
                              </TwitterShareButton>

                              <LinkedinShareButton url={shareUrl}>
                                <LinkedinIcon size={size} round />
                              </LinkedinShareButton>

                              <WhatsappShareButton url={shareUrl} title={title}>
                                <WhatsappIcon size={size} round />
                              </WhatsappShareButton>

                              <PinterestShareButton
                                url={shareUrl}
                                media={mediaUrl}
                              >
                                <PinterestIcon size={size} round />
                              </PinterestShareButton>
                              <img
                                onClick={() => {
                                  navigator.clipboard.writeText(shareUrl);
                                  setCopied(true);
                                }}
                                src={copied ? checkedIcon : copyIcon}
                                alt=""
                              />
                            </div>
                          </div>
                          <div>
                            <p>Next:</p>
                            <div
                              onClick={() => {
                                if (user) setCurrentLesson(next);
                              }}
                              className={styles.lesson}
                            >
                              <img src={next.thumb} alt="" />
                              <div className={styles.divider}></div>
                              <div className={styles.text}>
                                <p>{next.title}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p>Latest NFT:</p>
                            <div
                              onClick={() =>
                                window.open(
                                  "https://milliondollarvault.io/",
                                  "_blank"
                                )
                              }
                              className={styles.lesson}
                            >
                              <img src={banner} alt="" />
                              <div className={styles.divider}></div>
                              <div className={styles.text}>
                                <p>Check it Out</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : accInfo ? (
                <Login
                  user={user}
                  mode={0}
                  referrer={referrer}
                  setReferrer={setReferrer}
                  setUser={setUser}
                  setAccInfo={setAccInfo}
                  accInfo={accInfo}
                  mainPage={false}
                />
              ) : (
                <Leaderbord
                  shareUrl={shareUrl}
                  title={title}
                  size={size}
                  mediaUrl={mediaUrl}
                  leaderboard={leaderboard}
                  user={user}
                  setAccInfo={setAccInfo}
                />
              )
            }
          ></Route>
          <Route exact path="/ref=:val" element={
              !accInfo && !leadPage ? (
                <>
                  <h1>{currentLesson.title}</h1>
                  <div className={styles.vidsContainer}>
                    <div className={styles.mainVidContainer}>
                      <iframe
                        src={`https://player.vimeo.com/video/${currentLesson.link}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        title="Million Dollar Vault"
                      ></iframe>
                      <div className={styles.resources}>
                        {currentLesson.resources && <p>Resources:</p>}
                        {currentLesson.resources &&
                          currentLesson.resources.map((el, ind) => (
                            <button
                              key={ind}
                              onClick={() => window.open(el.link, "_blank")}
                            >
                              {el.title}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className={styles.others}>
                      {currentLesson.id === 0 && !user ? (
                        <div className={styles.formWrapper}>
                          <Login
                            user={user}
                            mode={1}
                            referrer={referrer}
                            setReferrer={setReferrer}
                            setUser={setUser}
                            setAccInfo={setAccInfo}
                            accInfo={accInfo}
                            mainPage={true}
                          />
                        </div>
                      ) : (
                        <div className={styles.others}>
                          <div>
                            <p>Share:</p>
                            <div className={styles.shareIcons}>
                              <FacebookShareButton url={shareUrl} quote={title}>
                                <FacebookIcon size={size} round />
                              </FacebookShareButton>

                              <TwitterShareButton url={shareUrl} title={title}>
                                <TwitterIcon size={size} round />
                              </TwitterShareButton>

                              <LinkedinShareButton url={shareUrl}>
                                <LinkedinIcon size={size} round />
                              </LinkedinShareButton>

                              <WhatsappShareButton url={shareUrl} title={title}>
                                <WhatsappIcon size={size} round />
                              </WhatsappShareButton>

                              <PinterestShareButton
                                url={shareUrl}
                                media={mediaUrl}
                              >
                                <PinterestIcon size={size} round />
                              </PinterestShareButton>
                              <img
                                onClick={() => {
                                  navigator.clipboard.writeText(shareUrl);
                                  setCopied(true);
                                }}
                                src={copied ? checkedIcon : copyIcon}
                                alt=""
                              />
                            </div>
                          </div>
                          <div>
                            <p>Next:</p>
                            <div
                              onClick={() => {
                                if (user) setCurrentLesson(next);
                              }}
                              className={styles.lesson}
                            >
                              <img src={next.thumb} alt="" />
                              <div className={styles.divider}></div>
                              <div className={styles.text}>
                                <p>{next.title}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p>Latest NFT:</p>
                            <div
                              onClick={() =>
                                window.open(
                                  "https://milliondollarvault.io/",
                                  "_blank"
                                )
                              }
                              className={styles.lesson}
                            >
                              <img src={banner} alt="" />
                              <div className={styles.divider}></div>
                              <div className={styles.text}>
                                <p>Check it Out</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : accInfo ? (
                <Login
                  user={user}
                  mode={0}
                  referrer={referrer}
                  setReferrer={setReferrer}
                  setUser={setUser}
                  setAccInfo={setAccInfo}
                  accInfo={accInfo}
                  mainPage={false}
                />
              ) : (
                <Leaderbord
                  shareUrl={shareUrl}
                  title={title}
                  size={size}
                  mediaUrl={mediaUrl}
                  leaderboard={leaderboard}
                  user={user}
                  setAccInfo={setAccInfo}
                />
              )}></Route>
          <Route exact path="/action" element={<Action />}></Route>
        </Routes>
      </Router>
    </div>
  );
}
