import styles from "./Header.module.scss";
import contactIcon from "../../assets/contact.png";
import twitterIcon from "../../assets/twitter2.png";
import discordIcon from "../../assets/DiscordIcon2.png";
import menuIcon from "../../assets/menu.png";
import nftFeverLogo from "../../assets/NFTFeverSmall.png";
import prizeIcon from "../../assets/prizeIcon.png";

export default function Header({
  setNavOpen,
  setAccInfo,
  user,
  setDiscordModal,
  setLeadPage,
}) {
  return (
    <div className={styles.container}>
      <div>
        <button onClick={() => setNavOpen(true)}>
          <img src={menuIcon} alt="" />
        </button>
      </div>
      <div>
        <p>The FREE NFT crash course for beginners</p>
        <img className={styles.logo} src={nftFeverLogo} alt="" />
      </div>
      <div className={styles.btns}>
        <div className={styles.wrapper}>
          <button
            onClick={() => {
              setLeadPage(true);
            }}
          >
            <img src={prizeIcon} alt="" />
          </button>
        </div>
        <div onClick={() => window.open("mailto:team@nftfever.io", "_self")}>
          <img src={contactIcon} alt="" />
        </div>
        <div onClick={() => setDiscordModal(true)}>
          <img src={discordIcon} alt="" />
        </div>
        <div
          onClick={() =>
            window.open("https://twitter.com/NFTFeverio", "_blank")
          }
        >
          <img src={twitterIcon} alt="" />
        </div>

        <div className={styles.wrapper}>
          <button onClick={() => setAccInfo(true)}>
            {user ? "SIGN OUT" : "SIGN IN"}
          </button>
        </div>
      </div>
    </div>
  );
}
