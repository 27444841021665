import { useState, useEffect } from "react";
import styles from "./App.module.scss";
import Nav from "./components/navBar";
import Header from "./components/header";
import Footer from "./components/footer";
import Main from "./components/mainContent";
import Dis from "./components/joinDiscord";
import app from "./utils/fire.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

var auth = getAuth(app);
var db = app.firestore();

function App() {
  const [user, setUser] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [leadPage, setLeadPage] = useState(false);
  const [accInfo, setAccInfo] = useState(false);
  const [discordModal, setDiscordModal] = useState(false);

  const [currentLesson, setCurrentLesson] = useState({
    title: "welcome",
    link: "647894567?h=1cc8ba82e9",
    resources: [
      {
        link: "https://metamask.io/",
        title: "MetaMask",
      },
    ],
    id: 0,
  });

  useEffect(() => {
    if (accInfo) {
      setLeadPage(false);
    }
  }, [accInfo]);

  useEffect(() => {
    setLeadPage(false);
  }, [currentLesson]);
  useEffect(() => {
    // get the refferer id if exists

    var currentUrl = window.location.href;
    var splitUrl = currentUrl.split("/")[3].split("=");

    setReferrer(splitUrl[splitUrl.length - 1]);

    onAuthStateChanged(auth, (user) => {
      if (user) {
        var uid = user.uid;
        var emailVerified = user.emailVerified;

        if (emailVerified === true) {
          setReferrer(user.uid.slice(0, 5));
          setUser(user);
        }
      } else {
        console.log("not signed in");
      }
    });

    if (!user) {
      setCurrentLesson({
        title: "Welcome",
        link: "666572835?h=ee45cb228f",
        resources: null,
        id: 0,
      });
    }
  }, [user]);

  const [next, setNext] = useState({});
  const [navOpen, setNavOpen] = useState(false);

  const leaderBoard = async (id, limit) => {
    try {
      let documentSnapshot;

      if (!limit) {
        console.log("limit is undefined");
      }

      if (!id) {
        documentSnapshot = await db
          .collection("verifiedEmails")
          .orderBy("count", "desc")
          .limit(parseInt(limit))
          .get();
      } else {
        const userDocumentSnapshot = await db
          .collection("verifiedEmails")
          .doc(id)
          .get();
        documentSnapshot = await db
          .collection("verifiedEmails")
          .orderBy("count", "desc")
          .startAfter(userDocumentSnapshot)
          .limit(parseInt(limit))
          .get();
      }

      let transformArray = [];

      documentSnapshot.forEach((res) => {
        transformArray.push({
          id: res.id,
          ...res.data(),
        });
      });
      // console.log("func data", transformArray);
      setLeaderboard(transformArray);
      return transformArray;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    leaderBoard(null, 100);
  }, []);

  return (
    <div className={styles.container}>
      {discordModal && <Dis setDiscordModal={setDiscordModal} />}
      <Nav
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        setCurrentLesson={setCurrentLesson}
        user={user}
        setAccInfo={setAccInfo}
        currentLesson={currentLesson}
        setNext={setNext}
        next={next}
      />
      <main
        onClick={() => {
          if (navOpen) {
            setNavOpen(false);
          }
        }}
      >
        <Header
          setDiscordModal={setDiscordModal}
          setNavOpen={setNavOpen}
          setAccInfo={setAccInfo}
          user={user}
          setLeadPage={setLeadPage}
        />
        <Main
          setAccInfo={setAccInfo}
          accInfo={accInfo}
          user={user}
          referrer={referrer}
          setReferrer={setReferrer}
          setUser={setUser}
          currentLesson={currentLesson}
          next={next}
          setCurrentLesson={setCurrentLesson}
          leaderboard={leaderboard}
          setLeadPage={setLeadPage}
          leadPage={leadPage}
        />
        <Footer />
      </main>
    </div>
  );
}

export default App;
